<template>
  <div class="m-1 mb-2 integracao-planilha">
    <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
      <b-form @submit.prevent="importar" ref="formulario">
        <b-row class="descricao">
          <b-col md="12" class="mb-2 p-0">
            <p class="m-0" v-text="descricao"></p>
          </b-col>
        </b-row>

        <b-row class="formulario d-flex justify-content-center mb-3 mb-sm-2">
          <b-col sm="12" md="6" lg="4" class="informativo">
            <span class="icone left">
              <feather-icon icon="DownloadIcon" class="DownloadIcon" />
            </span>
            <p>
              <a :href="Configuracao.linkDownload">Clique aqui</a>
              <span> {{ textoInformativo }}</span>
            </p>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            class="arquivo"
            :class="bloqueiaAcao == true ? 'bloquear-acao' : ''"
          >
            <div class="vertical-center">
              <div class="w-100">
                <p>Importar Planilha:</p>
                <b-form-file
                  name="arquivo"
                  :id="`arquivo-${Configuracao.id}`"
                  accept=".csv"
                  placeholder="Nenhum arquivo selecionado"
                  :multiple="false"
                  v-model="Form.arquivo"
                  :disabled="bloqueiaAcao"
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            v-if="bloqueiaAcao"
            md="12"
            class="botao d-flex justify-content-center bloquear-acao"
          >
            <b-button type="submit" variant="custom" disabled
              >Importar</b-button
            >
          </b-col>

          <b-col v-else md="12" class="botao d-flex justify-content-center">
            <b-button type="submit" variant="custom" :disabled="!Form.arquivo"
              >Importar</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormFile,
  BForm,
  BButton,
  BRow,
  BModal,
  BCol,
  BFormInput,
  BOverlay,
} from "bootstrap-vue";
import { modalGenericModel } from '@/libs/sweetalerts'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BForm,
    BButton,
    BRow,
    BModal,
    BCol,
    BFormInput,
    BOverlay,
  },
  props: {
    Grupo: {
      type: Object,
      required: false,
    },
    Configuracao: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      mostrarConfirmacao: false,
      mensagemErro: null,
      loadingShow: true,
      bloqueiaAcao: true,
      Form: {
        arquivo: null,
      },
    };
  },
  created() {
    this.loadingShow = false;
  },
  beforeMount() {
    this.bloqueiaAcao = this.grupoNaoInformado();
  },
  methods: {
    grupoNaoInformado() {
      return (
        typeof this.Grupo.id_empresa_grupo == "undefined"
      );
    },
    modalSucesso() {
      this.Form.arquivo = null;
      this.mostrarLoading(true)
      modalGenericModel(
        'Importação em andamento',
        'Notificaremos assim que finalizar',
        require('@/assets/custom-icons/cora-icons/success.png'),
        'Ok'
      ).then(() => {
        this.mostrarLoading(false)
      })
    },
    modalErro() {
      this.mostrarLoading(true)
      modalGenericModel(
        'Não foi possível realizar a importação',
        this.mensagemErro,
        require('@/assets/custom-icons/cora-icons/error.png'),
        'Ok'
      ).then(() => {
        this.mostrarLoading(false)
      })
    },
    mostrarLoading(ativo) {
      this.$emit("AtivarLoading", ativo);
    },
    importar() {
      this.loadingShow = true;
      let idArquivo = `#arquivo-${this.Configuracao.id}`,
        formData = new FormData(),
        imagefile = document.querySelector(idArquivo);

      formData.append("arquivo", imagefile.files[0]);
      formData.append("nome", this.Form.arquivo.name);
      formData.append("id_empresa_grupo", this.Grupo.id_empresa_grupo);

      this.$http
        .post(this.Configuracao.rotaApi, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.loadingShow = false;
          this.modalSucesso();
        })
        .catch((error) => {
          this.mensagemErro = "Tente novamente mais tarde."
          if(error.response?.data?.error?.conteudo){
            this.mensagemErro = []
            for(let value of Object.values(
              error.response.data.error.conteudo
            )){
              this.mensagemErro += value + '<br>'
            }
            this.mensagemErro = this.mensagemErro.replaceAll("\n", "<br>");
          }
          this.loadingShow = false;
          this.modalErro()
        });
    }
  },
  watch: {
    Grupo: function () {
      this.bloqueiaAcao = this.grupoNaoInformado();
    }
  },
  computed: {
    descricao() {
      return `Siga os passos a seguir para realizar a implementação de ${this.Configuracao.id} corretamente:`;
    },
    textoInformativo() {
      let tipo = "";
      switch (this.Configuracao.id) {
        case "ciclo":
          tipo = " de ciclos";
          break;
        case "perfil":
          tipo = " de perfis";
          break;
      }

      return `e baixe a planilha modelo de importação${tipo}. Preencha os campos e salve no formato CSV.`;
    },
  },
};
</script>

<style>
#grupo-pessoas-importar .b-form-file .custom-file-label,
#grupo-ciclos-importar .b-form-file .custom-file-label {
  height: 101% !important;
}
#grupo-pessoas-importar .b-form-file .custom-file-label::after,
#grupo-ciclos-importar .b-form-file .custom-file-label::after {
  background: #f6f9fc !important;
  color: #2772c0;
  font-weight: 500;
  size: 14px;
  content: "Escolher Arquivo" !important;
  border: 1px solid #2772c0;
}

#modal-erro-importacao,
#modal-sucesso-importacao {
  padding: 50px;
}
#modal-erro-importacao header,
#modal-erro-importacao footer,
#modal-sucesso-importacao header,
#modal-sucesso-importacao footer {
  display: none;
}

.integracao-planilha .descricao p {
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 14.63px;
}

.integracao-planilha .formulario .arquivo {
  border: 1px solid #efefef;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0 25px;
}

.integracao-planilha .formulario .informativo {
  padding: 20px;
  background: #efefef;
  display: block;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.integracao-planilha .formulario .informativo p {
  /*overflow: hidden;*/
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  margin: 0 !important;
}

.integracao-planilha .formulario .informativo .icone {
  background: #2772c0;
  color: #fff;
  border-radius: 10px;
  float: left;
  margin-right: 1.5em;
}

.integracao-planilha .formulario .informativo .icone .DownloadIcon {
  height: 20px;
  width: 20px;
  margin: 25px;
}

.arquivo p {
  color: #5e5873;
  font-family: "Montserrat", sans-serif;
  font-style: Medium;
  font-size: 14px;
  line-height: 13px;
  line-height: 100%;
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .integracao-planilha .formulario .informativo {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .integracao-planilha .formulario .arquivo {
    padding: 25px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}
.bloquear-acao button {
  background-color: #82868b !important;
  border-color: #82868b !important;
}

.bloquear-acao .custom-file-input:lang(en) ~ .custom-file-label::after {
  background: #aeb0b4 !important;
  border-color: #aeb0b4 !important;
  color: white;
}
</style>
