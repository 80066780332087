<template>
  <div id="detalhe-grupo-filiais">
    <b-overlay
      :show="loadingShow"
      rounded="lg"
      opacity="0.6"
    >
      <div
        v-if="Object.keys(grupo).length"
        id="grupo-detalhe"
        class="bg-white"
      >
        <!-- cabecalho -->
        <b-row class="mb-3">
          <b-col
            md="9"
          ><h1>Buscar Pessoas</h1>
            <p>
              Para realizar uma busca, selecione o(s) filtro(s) necessário(s) e
              clique no botão buscar:
            </p>
          </b-col>
          <b-col
            md="3"
            class="text-md-right text-left mt-md-0 mt-1"
          >
            <b-button
              v-if="$can('INSERIR','PORTAL_GRUPO_PESSOAS')"
              type="button"
              variant="custom"
              class="botao-cadastrar"
              @click="redirecionaCriarPessoa"
            >
              <feather-icon icon="PlusCircleIcon" />
              Cadastrar
            </b-button>
          </b-col>
        </b-row>
        <section class="filtros mt-2">
          <validation-observer ref="validacaoFiltros">
            <b-form>
              <b-row>
                <b-col
                  xl="3"
                  md="6"
                >
                  <b-form-group
                    label="Nome"
                    label-for="campo-razao"
                  >
                    <b-form-input
                      id="campo-razao"
                      v-model="form.nome"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xl="3"
                  md="6"
                >
                  <b-form-group
                    label="CPF"
                    label-for="campo-cpf"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="regex:^\d{3}\d{3}\d{3}\d{2}$"
                      name="regex"
                      placeholder="cpf"
                    >
                      <cleave
                        id="campo-cpf"
                        v-model="form.cpf"
                        class="form-control"
                        :options="masks.cpf"
                      />
                      <small class="text-danger">{{
                        errors[0] ? "O cpf precisa estar completo" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  xl="3"
                  md="6"
                >
                  <b-form-group
                    label="Perfil"
                    label-for="Select-Perfil"
                  >
                    <v-select
                      id="Select-Perfil"
                      v-model="form.perfil"
                      multiple
                      multiselect
                      variant="custom"
                      item-text="descricao"
                      item-value="id_perfil"
                      label="descricao"
                      :options="Perfis"
                      placeholder="Selecione 1 ou mais"
                      @input="getPerfil"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xl="3"
                  md="6"
                >
                  <b-form-group
                    label="Líder"
                    label-for="Select-Lider"
                  >
                    <v-select
                      id="Select-Lider"
                      v-model="form.lider"
                      multiple
                      multiselect
                      variant="custom"
                      item-text="nome"
                      item-value="id_colaborador"
                      label="nome"
                      :options="Lideres"
                      placeholder="Selecione 1 ou mais"
                      @input="getLider"
                    >
                      <span slot="no-options">Nenhum Líder encontrado.</span>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-collapse
                id="collapse-1"
                v-model="showBuscaAvancada"
                class="mt-2"
              >
                <div class="buscaAvancada">
                  <b-row>
                    <b-col
                      xl="3"
                      md="6"
                    >
                      <b-form-group
                        label="A partir da data de admissão"
                        label-for="Pessoa-admissao"
                      >
                        <b-form-input
                          id="admissao"
                          v-model="form.dtAdmissao"
                          name="Pessoa-admissao"
                          placeholder="01/01/2000"
                          type="date"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      xl="3"
                      md="6"
                    >
                      <b-form-group
                        label="Setor"
                        label-for="Select-Setor"
                      >
                        <v-select
                          id="Select-Setor"
                          v-model="form.setor"
                          multiple
                          multiselect
                          variant="custom"
                          item-text="descricao"
                          item-value="id_empresa_setor"
                          label="descricao"
                          :options="Setores"
                          placeholder="Selecione 1 ou mais"
                          @input="getSetor"
                        >
                          <span
                            slot="no-options"
                          >Nenhum Setor encontrado.</span>
                        </v-select>
                      </b-form-group>
                    </b-col>
                    <b-col
                      xl="3"
                      md="6"
                    >
                      <b-form-group
                        label="Função"
                        label-for="Select-Funcao"
                      >
                        <v-select
                          id="Select-Funcao"
                          v-model="form.funcao"
                          multiple
                          multiselect
                          variant="custom"
                          item-text="descricao"
                          item-value="id_empresa_funcao"
                          label="descricao"
                          :options="Funcoes"
                          placeholder="Selecione 1 ou mais"
                          @input="getFuncao"
                        >
                          <span
                            slot="no-options"
                          >Nenhuma Função encontrada.</span>
                        </v-select>
                      </b-form-group>
                    </b-col>
                    <b-col
                      xl="3"
                      md="6"
                    >
                      <b-form-group
                        label="Turno"
                        label-for="Select-Turno"
                      >
                        <v-select
                          id="Select-Turno"
                          v-model="form.turno"
                          multiple
                          multiselect
                          variant="custom"
                          item-text="descricao"
                          item-value="id_empresa_turno"
                          label="descricao"
                          :options="Turnos"
                          placeholder="Selecione 1 ou mais"
                          @input="getTurno"
                        >
                          <span
                            slot="no-options"
                          >Nenhum Turno encontrado.</span>
                        </v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-collapse>
              <b-row class="mb-3">
                <b-col md="9" />
                <b-col md="3">
                  <b-button
                    type="button"
                    variant="busca"
                    class="busca"
                    @click="buscaAvancada"
                  >
                    {{ textoBotaoBuscaAvancada }}
                  </b-button>
                </b-col>
              </b-row>
              <div class="w-100 d-flex justify-content-center my-2">
                <b-button
                  class="mr-2"
                  type="reset"
                  variant="outline-secondary"
                  @click="limparFiltros"
                >Limpar</b-button>
                <b-button
                  ref="filtrosEmpresa"
                  type="submit"
                  variant="custom"
                  @click.prevent="validaFiltros"
                >Buscar</b-button>
              </div>
            </b-form>
          </validation-observer>
        </section>
        <!-- cabecalho -->

        <!-- conteudo -->

        <section v-if="mostraTabela">
          <PessoaTable
            :itens-tabela="itensTabela"
            :pagina-buscar-empresa="false"
            :pagina-atual="paginaAtual"
            :grupo-modulos="grupo.modulos"
            :grupo="grupo"
            :filtro-lider-ativo="filtroLiderAtivo"
            @showModal="redirecionaEditarPessoa"
            @showModalEditarLider="mostraModalEditaLider"
          />
        </section>
        <b-modal
          id="modal-novo-lider"
          ref="modal-novo-lider"
          v-model="showModalEditaLider"
          centered
          :no-close-on-backdrop="true"
          hide-footer
          title="Seleção de novo líder"
          size="m"
          @close="fechaModalEditaLider"
        >
          <PessoaLiderForm
            v-if="true"
            :prop-lote-colaboradores="loteColaboradoresAlteraLider"
            :prop-empresa-matriz="this.grupo.id_empresa_matriz"
            :lider-selecionado-no-filtro="form.lider"
            @cancel="fechaModalEditaLider"
          />
        </b-modal>
        <!-- conteudo -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCollapse,
} from 'bootstrap-vue'
import PessoaTable from '@/views/components/custom/pessoas/PessoaTable.vue'
import CriarPessoaForm from '@/views/pages/grupos/CriarPessoaForm.vue'
import PessoaLiderForm from '@/views/components/custom/pessoas/PessoaLiderForm.vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    PessoaLiderForm,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BButton,
    PessoaTable,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    CriarPessoaForm,
    vSelect,
    BCollapse,
  },
  props: {
    grupo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showModalEditaLider: false,
      loadingShow: false,
      itensTabela: [],
      mostraTabela: false,
      loteColaboradoresAlteraLider: [],
      showBuscaAvancada: false,
      textoBotaoBuscaAvancada: 'Busca Avançada',
      Perfis: [],
      Lideres: [],
      paginaAtual: 3,
      Turnos: [],
      Setores: [],
      Funcoes: [],
      filtroLiderAtivo: false,

      masks: {
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
      },
      form: {
        cpf: '',
        razao: '',
        perfil: [],
        lider: [],
        setor: [],
        funcao: [],
        turno: [],
        dtAdmissao: '',
      },
    }
  },
  watch: {
    'form.lider': function (novoValor) {
      this.filtroLiderAtivo = !!novoValor.length
    },
  },

  created() {
    this.buscaPerfil()
    this.buscaLider()
    this.buscaTurno()
    this.buscaFuncao()
    this.buscaSetor()
  },

  mounted() {
    this.validaFiltros()
  },

  methods: {
    redirecionaCriarPessoa() {
      this.$emit("trocaComponente", {
        componente: 'GrupoPessoas',
        subComponente: 'CriarPessoaForm'
      });
    },
    redirecionaEditarPessoa(pessoa) {
      this.$emit("trocaComponente", {
        componente: 'GrupoPessoas',
        subComponente: 'EditarPessoaForm',
        edicaoPessoa: pessoa
      });
    },
    mostraModalEditaLider(lote) {
      this.showModalEditaLider = true
      this.loteColaboradoresAlteraLider = lote
    },
    fechaModalEditaLider() {
      this.validaFiltros()
      this.showModalEditaLider = false
    },
    validaFiltros() {
      this.$refs.validacaoFiltros.validate().then(success => {
        if (success) {
          this.loadingShow = true
          this.getColaboradoresGrupo()
          this.mostraTabela = true
        }
      })
    },
    lidarComParametrosBuscaEmpresa() {
      const objetoRetorno = {
        id_empresa_grupo: this.$route.params.idGrupo
      }
      const perfis = this.$helpers.handlePluck(
        this.form.perfil,
        'id_perfil',
      )
      const lideres = this.$helpers.handlePluck(
        this.form.lider,
        'id_colaborador',
      )
      const setores = this.$helpers.handlePluck(
        this.form.setor,
        'id_empresa_setor',
      )
      const funcoes = this.$helpers.handlePluck(
        this.form.funcao,
        'id_empresa_funcao',
      )
      const turnos = this.$helpers.handlePluck(
        this.form.turno,
        'id_empresa_turno',
      )
      if (
        this.form.nome != null &&
        this.form.nome != ''
      ) objetoRetorno.nome = this.form.nome
      if (
        this.form.cpf != null &&
        this.form.cpf != ''
      ) objetoRetorno.cpf = this.form.cpf
      if (
        this.form.dtAdmissao != null &&
        this.form.dtAdmissao != ''
      ) objetoRetorno.data_admissao = this.form.dtAdmissao
      if (perfis.length > 0) objetoRetorno.perfis           = perfis
      if (lideres.length > 0) objetoRetorno.lideres         = lideres
      if (setores.length > 0) objetoRetorno.empresa_setores = setores
      if (funcoes.length > 0) objetoRetorno.empresa_funcoes = funcoes
      if (turnos.length > 0) objetoRetorno.empresa_turnos   = turnos

      return objetoRetorno
    },

    buscaAvancada() {
      this.limpaFiltrosAvancados()
      this.showBuscaAvancada = !this.showBuscaAvancada
      this.textoBotaoBuscaAvancada = this.showBuscaAvancada
        ? 'Busca Simples'
        : 'Busca Avançada'
    },
    limpaFiltrosAvancados() {
      this.form.turno = []
      this.form.funcao = []
      this.form.setor = []
      this.form.dtAdmissao = ''
    },
    limparFiltros() {
      this.form.cpf = ''
      this.form.nome = ''
      this.form.perfil = []
      this.form.lider = []
      this.limpaFiltrosAvancados()
    },
    getLider(EstadoLider) {
      this.form.lider = EstadoLider
    },
    getPerfil(EstadoPerfil) {
      this.form.perfil = EstadoPerfil
    },
    getTurno(EstadoTurno) {
      this.form.turno = EstadoTurno
    },
    getFuncao(EstadoFuncao) {
      this.form.funcao = EstadoFuncao
    },
    getSetor(EstadoSetor) {
      this.form.setor = EstadoSetor
    },
    getColaboradoresGrupo() {
      const url = this.$api.ColaboradoresGrupo
      try {
        this.$http
          .get(url, {
            params: this.lidarComParametrosBuscaEmpresa(),
          })
          .then(res => {
            this.itensTabela = res.data
            this.loadingShow = false
          })
      } catch (err) {}
    },
    buscaPerfil() {
      this.$http.get(this.$api.PerfilBusca, { params: { tipoPerfil: 2 } }).then(res => {
        this.Perfis = res.data
      })
    },
    buscaSetor() {
      const rota = this.$helpers.preparaRotaComParametros(
        this.$api.SetorBusca,
        '{grupo}',
        this.$route.params.idGrupo,
      )
      this.$http.get(rota).then(res => {
        this.Setores = res.data
      })
    },
    buscaTurno() {
      const rota = this.$helpers.preparaRotaComParametros(
        this.$api.TurnoBusca,
        '{grupo}',
        this.$route.params.idGrupo,
      )
      this.$http.get(rota).then(res => {
        this.Turnos = res.data
      })
    },
    buscaFuncao() {
      const rota = this.$helpers.preparaRotaComParametros(
        this.$api.FuncaoBusca,
        '{grupo}',
        this.$route.params.idGrupo,
      )
      this.$http.get(rota).then(res => {
        this.Funcoes = res.data
      })
    },

    buscaLider() {
      const params = {
        id_empresa_grupo: this.$route.params.idGrupo
      }

      this.$http.get(this.$api.LiderBusca,
        { params }
      ).then(res => {
        this.Lideres = res.data
        this.Lideres.forEach(lider => {
          lider.nome = this.$helpers.removeAcentos(lider.nome)
        })
      })
    },

    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy
      this.tabela.ordem = contextoTabela.sortDesc ? 'desc' : 'asc'

      this.buscarDadosEmpresa()
    },

    getDescritivoRegionais() {
      return this.$helpers.retornaStringConcatenadaDeUmArrayMultiNivel(
        this.grupo.matriz.regionais,
        'descricao',
        'Não informado',
      )
    },
    getDescritivoModulos() {
      return this.$helpers.retornaStringConcatenadaDeUmArrayMultiNivel(
        this.grupo.modulos,
        'descricao',
        'Não possui',
      )
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/custom/grupo.scss";
</style>
<style scoped>
.botao-cadastrar {
  background: white;
  color: #7030A0;
}
</style>
