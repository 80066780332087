<template>
  <div id="detalhe-grupo-matriz">
    <b-overlay
      :show="loadingShow"
      rounded="lg"
      opacity="0.6"
    >
      <div
        v-if="Object.keys(grupo).length"
        id="grupo-detalhe"
        class="bg-white"
      >
        <!-- cacebalho -->
        <b-row class="mb-3">
          <b-col
            md="10"
            sm="12"
          >
            <h1>Matriz</h1>
          </b-col>
          <b-col
            md="2"
            sm="12"
          >
            <b-button
              type="button"
              variant="custom"
              class="botao float-lg-right"
              @click="showModal"
            >
              Editar
            </b-button>
          </b-col>
        </b-row>
        <!-- cacebalho -->

        <b-row class="mb-3 mb-sm-0">
          <b-col md="3">
            <h5>Razão Social</h5>
            <p v-text="Matriz.razao" />
          </b-col>
          <b-col md="3">
            <h5>Nome Fantasia</h5>
            <p v-text="Matriz.nome_fantasia" />
          </b-col>
          <b-col md="3">
            <h5>CNPJ</h5>
            <p>
              <template>
                <span>{{ Matriz.cnpj | VMask("##.###.###/####-##") }}</span>
              </template>
            </p>
          </b-col>
          <b-col md="3">
            <h5>SESI Responsável</h5>
            <p v-text="getDescritivoRegionais" />
          </b-col>
        </b-row>
        <b-row class="mb-3 mb-sm-0">
          <b-col md="3">
            <h5>CEP</h5>
            <p>
              <template>
                <span>{{ Matriz.cep | VMask("##.###-###") }}</span>
              </template>
            </p>
          </b-col>
          <b-col md="3">
            <h5>Estado</h5>
            <p v-text="Matriz.cidade.estado.nome" />
          </b-col>
          <b-col md="3">
            <h5>Cidade</h5>
            <p v-text="Matriz.cidade.nome" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <h5>Pessoa Responsável</h5>
            <p v-text="nomeResponsavel" />
          </b-col>
          <b-col md="3">
            <h5>Serviços Contratados</h5>
            <p v-text="getDescritivoModulos" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>

    <!-- Modal editar matriz -->
    <modal-form
      :modal-state="mostrarModal"
      :modal-title="getTituloModal"
      @closeModal="esconderModal"
    >
      <atualizar-empresa
        :use-title="false"
        :empresa="getEmpresa"
        @clearForm="esconderModal"
      />
    </modal-form>
  </div>
</template>

<script>
import {
  BOverlay, BRow, BCol, BButton,
} from 'bootstrap-vue'
import ModalForm from '@/views/pages/empresas/components/ModalForm.vue'
import AtualizarEmpresa from '@/views/pages/empresas/AtualizarEmpresa.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BButton,
    ModalForm,
    AtualizarEmpresa,
  },
  props: {
    grupo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingShow: false,
      Matriz: this.grupo.matriz,
      nomeResponsavel: '',

      mostrarModal: false,
    }
  },
  computed: {
    getDescritivoRegionais() {
      return this.$helpers.retornaStringConcatenadaDeUmArrayMultiNivel(
        this.Matriz.regionais,
        'descricao',
        'Não informado',
      )
    },
    getDescritivoModulos() {
      return this.$helpers.retornaStringConcatenadaDeUmArrayMultiNivel(
        this.grupo.modulos,
        'descricao',
        'Não possui',
      )
    },

    getEmpresa() {
      return this.$store.state.modal.empresa
    },

    getTituloModal() {
      return this.$store.state.modal.tituloModal
    },
  },

  watch: {
    '$store.state.modal.modalAtualizarMatriz': {
      immediate: true,
      handler() {
        this.mostrarModal = this.$store.state.modal.modalAtualizarMatriz
      },
    },
  },

  beforeMount() {
    this.nomeResponsavel = this.grupo.matriz.responsaveis
      ? this.grupo.matriz.responsaveis.nome
      : ''
    this.alertResponsaveis = true
  },
  methods: {
    showModal() {
      this.$store.commit('modal/exibirModalAtualizarMatriz', {
        tituloModal: 'Editar - Matriz',
        empresa: this.Matriz,
        tipoEmpresa: 1,
      })
    },

    esconderModal() {
      this.$store.commit('modal/limparState')

      this.$emit('atualizarPagina', false)
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/custom/grupo.scss";
</style>
